.webApp {
  width: 100%;
  height: 100vh;
  min-height: 520px;
  background: url("../../assets/bakery_background_mobile.png") no-repeat;
  background-size: cover;
  background-color: var(--color-primary);
}

.headerLogoContainer {
  width: 70%;
  display: block;
  padding: 2em 0 0 0;
  margin: auto;
}

.headerLogo {
  width: 100%;
}

.headerSection {
  text-align: center;
  padding: 3em 0 0 0;
}

.header {
  display: none;
}

.lottie {
  width: 80%;
  position: relative;
  top: -2em;
  margin: auto;
}

.headerDescription {
  text-align: start;
  font-size: 1.1rem;
  padding: 0 4em 0 4em;
}

.headerSection h3,
.headerDescription p {
  color: #FFF;
}

.whatsAppLogo > img,
.instagramLogo > img,
.facebookLogo > img {
  background-color: #FFF;
  border-radius: 7px;
  border: 2px #FFF solid;
}

/* Extra small devices (small tablets, 600px and down) */
@media only screen and (min-width: 600px) {
  .webApp {
    background: url("../../assets/bakery_background_tablet.png") no-repeat;
    background-size: contain;
  }

  .headerContainer {
    padding: 0 0 0 3em;
  }

  .headerLogoContainer {
    width: 50%;
    padding: 1em 0 0 0;
  }

  #headerRow {
    margin: 3em 0 0 0 !important;
  }

  .smallHeader {
    display: none;
  }

  .header {
    display: block;
  }

  .headerSection {
    padding: 6.5em 0 0 0em;
    text-align: start;
  }

  .headerSection h3 {
    color: #000000;
  }

  .headerDescription {
    padding: 0 0 0 0;
  }

  .headerDescription p {
    font-size: 1rem;
    color: #7C7C7C;
  }

  .lottie {
    display: none;
  }

  .whatsAppLogo {
    justify-content: center !important;
  }

  .facebookLogo {
    justify-content: center !important;
  }

  #socialMediaContainer {
    margin: auto;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

  #headerRow {
    margin: 5em 0 0 0 !important;
  }
}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .webApp {
    background-color: white;
    width: 100%;
    height: 100vh;
    background: url("../../assets/bakery_background.png") no-repeat;
    background-size: contain;
    background-position: right calc(-100px) top;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .headerLogoContainer {
    margin: 0 !important;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
  }

  .headerLogo {
    width: 75%;
    display: block;
    margin: auto;
  }

  #headerRow {
    flex-flow: column;
    margin: 0 !important;
    width: 50%;
    align-self: stretch;
  }

  .headerContainer {
    width: 100%;
  }

  .header {
    font-size: 2rem;
  }

  .headerDescription p {
    font-size: 1.5rem;
  }

  #socialMediaContainer {
    margin: 0 !important;
  }

  .container {
    justify-content: flex-start !important;
  }

  .whatsAppLogo,
  .instagramLogo,
  .facebookLogo {
    width: calc(100%/3);
    height: 50px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .webApp {
    background-position: right;
  }

  .headerContainer,
  #socialMediaContainer {
    display: block;
    margin: 0 auto !important;
    width: 100%;
    max-width: 720px;
  }

  .container {
    width: 100%;
  }

  .headerLogoContainer {
    display: flex;
    justify-content: flex-end;
  }

  .headerLogo {
    margin: 0 4em 0 0;
  }

}